<template>
  <div>
<!--    <div>-->
<!--      <van-image-->
<!--          round-->
<!--          width="8rem"-->
<!--          height="8rem"-->
<!--          style="width: 8rem;display: block;margin: 10px auto auto;"-->
<!--          :src="info.image"-->
<!--      />-->
<!--      <p style="text-align: center;font-size: 1em;">{{ info.name }}</p>-->
<!--    </div>-->

<!--    <div style="width: 90vw;display: block;margin: 10px auto auto;background-color: white;">
      <div style="padding: 5px">
        <span style="font-size: 0.9em;color: #333333;letter-spacing: 0.5px;">{{info.detailer}}</span>
      </div>
    </div>-->
    <div>
      <img style="width: 40vw;display: block;margin: 100px auto auto;" src="https://news-1259740107.cos.ap-singapore.myqcloud.com/logo%2Flogo_smc_md.png" />
    </div>
    <div style="width: 90vw;display: block;margin: 50px auto auto;">
      <van-collapse v-model="activeNames" v-for="(help, index) in helpList" :key="help.id" :border="false">
        <van-collapse-item v-if="langNum === 2" :title="help.titleCn" :name="index">
          {{ help.cn }}
        </van-collapse-item>
        <van-collapse-item v-if="langNum === 1" :title="help.titleEn" :name="index">
          {{ help.en }}
        </van-collapse-item>
      </van-collapse>
    </div>

    <footer class="footer">{{footInfo}}</footer>

  </div>
</template>

<script>
import {ref} from "vue";
import axios from "axios";
import {useRouter} from "vue-router";
import {Locale} from "vant";
import zhCN from "vant/es/locale/lang/zh-CN";
import enUS from "vant/es/locale/lang/en-US";
import {useI18n} from "vue-i18n";

export default {
  name: "person",
  setup() {
    const activeNames = ref(['1']);
    const router = useRouter();
    const aid = ref();
    const i18n = useI18n();
    const lang = ref('zh-CN');
    const langNum = ref(1);
    const footInfo = ref('@SG Chat All rights reserved.');
    const par = router.currentRoute.value.query
    if (par.lang) {
      lang.value = par.lang;
    }
    axios.get('rss/footInfo').then((e) => {
      footInfo.value = e.data.data;
    });
    const info = ref({
      id: '',
      image: '',
      name: ''
    })
    if (lang.value === "zh-CN") {
      langNum.value = 2;
      Locale.use("zh-CN", zhCN);
      i18n.locale.value = 'zh'

    } else {
      langNum.value = 1;
      Locale.use('en-US', enUS);
      i18n.locale.value = 'en'
    }


    const helpList = ref([]);
    aid.value = router.currentRoute.value.params.id;
    const getHelp = async () => {
      await axios.get('rss/help').then(e => {
        helpList.value = e.data.data;
      })
    }
    // const getUserInfo = async () => {
    //   await axios.get('rss/author', {
    //     params: {
    //       id: aid.value
    //     }
    //   }).then(e => {
    //     info.value = e.data.data;
    //     document.title = info.value.name
    //   });
    // }
    // getUserInfo();
    getHelp();
    return {
      activeNames,
      info,
      helpList,
      footInfo,
      langNum,
    };
  },
}
</script>

<style>
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 0.8em;
  color: #999999;
  text-align: center;
}
</style>
